<template>
  <div class="l-menu">
    <div
      :id="category.slug"
      v-for="(category, index) in filteredData"
      :key="category.id"
      :ref="el => { section[index] = el }"
    >
      <Typography
        class="l-menu__spacing"
        v-text="capitalizeText(category.name)"
        variant="subtitle2"
      />
      <div
        class="l-menu__productContentLeftCard l-menu__spacingCard"
      >
        <Card
          v-for="(item) in category.products"
          :key="item.id"
          :images="item.images"
          image-max-height="90px"
          image-min-width="80px"
          :title="capitalizeText(item.name)"
          :subtitle="item.description"
          :header-text="item.breadCrumbText || ''"
          display="flex"
          image-position="Right"
          rounded
          @click="
            pushTo(`/item-details/${selectedRestaurantId}/${deliveryType}/${item.slug}/${item.id}`)"
        >
          <Typography
            class="-fontBold -muted"
            v-text="convertTotal(item.price, locale)"
            variant="body2"
          />
        </Card>
        <Typography
          v-if="!category.products.length"
          class="-fontBold -muted"
          v-text="$t('widgets.cart.noProductsText')"
          variant="body2"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import {
  computed, onMounted, ref, inject, watch,
} from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

import Card from '@/components/common/Card/Card.vue';
import Trans from '@/mixins/translation';
import { capitalizeText, convertTotal } from '@/mixins/utils';
import { PRODUCTS } from '@/store/types';

const store = useStore();

const { locale } = useI18n();
const selectedRestaurantId = computed(() => store.state.restaurant.selectedRestaurant.id);
const deliveryType = computed(() => store.state.order.orderData.delivery_type);
const categories = computed(() => store.state.products.restaurantCategories);
const filteredData = computed(() => store.getters['products/getFilteredProducts']);
const router = useRouter();

const categoryData = ref([]);

const section = inject('section');

const flattenData = data => data.reduce((r, { subCategories, ...rest }) => {
  r.push(rest);
  if (subCategories?.length) {
    r.push(...flattenData(subCategories?.filter(d => d.products.length)));
  }

  return r;
}, []);

const pushTo = path => {
  const newPath = Trans.i18nRoutePath(path);

  return router.push(newPath);
};

const getData = () => {
  console.log('load change');
  const arr = []; const list = [];

  // eslint-disable-next-line array-callback-return
  categories.value.map(d => {
    const products = d.subCategories && d.subCategories.length
            && flattenData(d.subCategories).filter(item => item?.products?.length);

    if (products && products?.length) {
      products.map(pd => list.push(...pd.products));
    }

    arr.push({
      name: d.name,
      slug: d.slug,
      products: d.subCategories.length ? list : flattenData(d.products),
    });
  });
  categoryData.value = arr;
  store.commit(`products/${PRODUCTS.SET_CATEGORY_DATA}`, categoryData.value);
};

watch(() => categories.value, getData);

onMounted(() => {
  getData();
});
</script>

<style lang="scss" scoped>
@use "src/assets/styles/abstracts/mixins" as mx;
@use "src/assets/styles/abstracts/variables" as v;

.l-menu {
  margin-bottom: 50px;

  &__productContentLeftCard {
    display: grid;

    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    grid-gap: 2px;

    ::v-deep(.c-lazyImage__img) {
      min-height: 80px;
    }

    @include mx.bp('tablet') {
      grid-gap: 16px;
    }
  }

  &__spacing {
    padding-right: 30px;
    padding-left: 30px;

    @include mx.bp('tablet-wide') {
      padding: 0;
    }
  }

  .c-card {
    cursor: pointer;
  }
}

</style>
