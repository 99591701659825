<template>
  <section class="c-dashboardBanner">
    <ResponsiveImage
      :style="'height: 100%'"
      class="c-dashboardBanner__img"
      :images="[
        {
          url: $t('widgets.dashboard.banner.background[0].url'),
          format: $t('widgets.dashboard.banner.background[0].format'),
          altText: $t('widgets.dashboard.banner.background[0].altText'),
        },
        {
          url: $t('widgets.dashboard.banner.background[1].url'),
          format: $t('widgets.dashboard.banner.background[1].format'),
        },
      ]"
    />
    <div
      class="c-dashboardBanner__overlay"
    />

    <SectionContainer
      class="c-dashboardBanner__block h-pv-20"
      bg-color="inherit"
      size="md"
    >
      <div class="-heroText h-mb-20">
        <Typography
          v-text="restaurantName"
          variant="hero"
          is-dark-theme
        />
        <Typography
          is-dark-theme
          v-text="$t('widgets.dashboard.banner.headText',
                     {firstName: isAuthenticated ? userName : ''})"
          :variant="isMobile ? 'h3': 'hero'"
        />
      </div>
      <RecentOrders @update:selectedOrder="e=>selectedOrder=e" />
    </SectionContainer>
  </section>
  <RecentOrderModal :selected-order="selectedOrder" />
</template>

<script setup>
import { computed, inject, ref } from 'vue';
import { useStore } from 'vuex';

import RecentOrderModal from '@/components/common/RecentOrderModal.vue';
import RecentOrders from '@/components/common/RecentOrders.vue';
import ResponsiveImage from '@/components/common/ResponsiveImage.vue';

const isMobile = inject('isMobile');

const store = useStore();
const selectedOrder = ref(null);
const isAuthenticated = computed(() => store.state.auth.isAuthenticated);
const restaurantName = computed(() => store.state.restaurant.selectedRestaurant.name);
const userName = computed(() => store.state.auth.user.name);

</script>

<style lang="scss" scoped>
@use "src/assets/styles/abstracts/mixins" as mx;

.c-dashboardBanner {
  position: relative;

  width: 100%;
  height: 90vh;
  min-height: 500px;

  background: linear-gradient(to right, #F6F6F6 8%, #F0F0F0 18%, #F6F6F6 33%);

  @include mx.bp('tablet-wide') {
    height: 60vh;
  }

  &__overlay {
    @include mx.use-zeta-index('lowest');

    position: absolute;
    bottom: 0;

    width: 100%;
    height: 40vh;

    background: linear-gradient(180deg, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 1) 68%);

    @include mx.bp('tablet-wide') {
      height: 100%;

      background: rgba($color: #000000, $alpha: 0.3);
    }
  }

  &__block {
    @include mx.use-zeta-index('content');

    position: absolute;
    bottom: 0;

    width: 100%;

    .-heroText {
      width: 100%;

      @include mx.bp('tablet-wide') {
        width: 70%;
      }
      @include mx.bp('desktop-wide') {
        width: 50%;
      }
    }
  }
}
</style>
