<template>
  <Page
    :title="restaurantName"
  >
    <div class="c-dashboard">
      <BannerDashboard />
      <HouseSpecialities />
      <SectionContainer
        size="sm"
        bg-color="02"
      >
        <div class="c-dashboard__popularSearch">
          <div class="-layout1 c-dashboard__Spacing">
            <Typography
              v-text="$t('widgets.dashboard.popularSearch.title')"
              variant="subtitle1"
            />
            <Typography
              v-text="$t('widgets.dashboard.popularSearch.subtitle')"
              variant="body1"
            />
          </div>
          <BaseTextBox
            class="-layout2"
            :placeholder="$t('widgets.dashboard.popularSearch.inputPlaceholder')"
            name="searchProduct"
            id="searchProduct"
            icon="search"
            v-model="search"
            autocomplete="off"
          />
          <div
            class="-layout3"
            ref="containerButtonRef"
          >
            <div :class="stickyButton && '-fixedContent'">
              <div
                :class="['c-dashboard__popularSearchCategory']"
                v-if="categoryTags.length"
              >
                <div
                  v-show="!isMobile || stickyButton"
                  class="c-dashboard__popularSearchCategoryButton"
                >
                  <router-link
                    v-for="({ name, id }, index) in categoryTags"
                    :key="`${generateKey(id)}-${index}`"
                    :to="`#${generateKey(id)}`"
                    custom
                    v-slot="{ href, route, navigate, isActive }"
                  >
                    <a
                      :class="[
                        'c-customButton',
                        isActive &&
                          route.hash === $route.hash &&
                          '-isExactActive',
                      ]"
                      :href="href"
                      @click="navigate"
                    >{{ capitalizeText(name) }}</a>
                  </router-link>
                </div>
              <!-- Allergy -->
              <!--<button
                class="c__customButtonIcon"
              >
                Allergy
                <span class="c__customButtonIcon&#45;&#45;badge">2</span>
              </button>-->
              </div>
            </div>
          </div>
        </div>
        <div class="c-dashboard__productContent">
          <Menu v-if="categories.length" />
          <div class="c-dashboard__productContentRight h-hideMd">
            <Cart />
          </div>
        </div>
      </SectionContainer>
      <PaymentFooter v-if="hasItems" />
    </div>
  </Page>
</template>

<script setup>
import throttle from 'lodash.throttle';
import {
  computed, ref, onMounted, onUnmounted, provide, inject,
} from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { useStore } from 'vuex';

import Cart from '@/components/Cart.vue';
import Menu from '@/components/Menu.vue';
import Page from '@/components/Page.vue';
import BannerDashboard from '@/components/banner/BannerDashboard.vue';
import BaseTextBox from '@/components/common/BaseTextBox.vue';
import PaymentFooter from '@/components/payment/PaymentFooter.vue';
import { capitalizeText, generateKey } from '@/mixins/utils';
import { PRODUCTS } from '@/store/types';
import HouseSpecialities from '@/widgets/HouseSpecialities.vue';

const stickyButton = ref(false);
const containerButtonRef = ref();
const store = useStore();
const router = useRouter();

const routePath = useRoute();

const isMobile = inject('isMobile');
const categories = computed(() => store.state.products.restaurantCategories);

const isAuthenticated = computed(() => store.state.auth.isAuthenticated);
const categoryTags = computed(() => categories.value.map(d => ({
  name: capitalizeText(d.name),
  id: d.slug || '',
})));
const restaurantName = computed(() => store.state.restaurant.selectedRestaurant.name);
const deliveryDate = computed(() => store.state.order.orderData.delivery_date);
const deliveryTime = computed(() => store.state.order.orderData.delivery_time);
const deliveryType = computed(() => store.state.order.orderData.delivery_type);

const observer = ref(null);
const section = ref([]);
const hasItems = computed(() => !!store.state.cart.cart_items.products?.length);

provide('section', section);

const deliveryDateTime = computed(() => {
  if (!deliveryTime.value) {
    return null;
  }

  return `${deliveryDate.value} ${deliveryTime.value.slice(0, deliveryTime.value.indexOf('-'))}`;
});

const search = computed({
  get() {
    return store.state.products.searchText;
  },
  set(newValue) {
    store.commit(`products/${PRODUCTS.SET_SEARCH_TEXT}`, newValue);
  },
});

const onScroll = throttle(e => {
  const windowScrollTop = e.target.documentElement.scrollTop;
  const elementTop = containerButtonRef.value.offsetTop;

  stickyButton.value = elementTop - 100 < windowScrollTop;
});

const observe = entry => {
  if (entry) {
    observer.value.observe(entry);
  }
};

const observeSections = () => {
  section.value.forEach(sec => {
    observe(sec);
  });
};

const initObserver = () => {
  const options = {
    threshold: 0,
  };

  observer.value = new IntersectionObserver((entries, observers) => {
    const active = entries.find(e => e.isIntersecting);

    if (active) {
      // Todo: find a better way
      console.log('finished viewed target', active.target);
      observers.unobserve(active.target);
      // router.push({ hash: `#${active.target.id}` });
      // console.log(active.target, active.boundingClientRect.top - 150 < 0);
    }

    // Test
    entries.forEach(entry => {
      // if (entry.isIntersecting || entry.boundingClientRect.top < 0) {
      //   console.log('entry above');
      // } else {
      //   console.log('entry within | below');
      // }
      if (entry.isIntersecting) {
        if (entry.boundingClientRect.top - 150 < 0) {
          console.log('entry is above');
        } else {
          console.log('entry is below');
        }
      }

      // if (entry.isIntersecting) {
      //   const elem = entry.target;

      //   console.log('elem>>>>', elem);
      //   console.log('entry.intersectionRatio>>>>', entry.intersectionRatio);

      //   if (entry.intersectionRatio !== 1) {
      //     console.log('not reach top >>>', entry.intersectionRatio);
      //   }
      //   if (entry.intersectionRatio === 1) {
      //     console.log('reach top >>>', entry.intersectionRatio);
      //   }
      // }
    });
  }, options);

  observeSections();
};

onMounted(() => {
  // TODO: move this to router guards without importing the store into route/index.js
  window.addEventListener('scroll', onScroll);

  if (!store.state.restaurant.selectedRestaurant) {
    router.replace('/');
  } else {
    const payload = {
      id: routePath.params.restaurantId,
      delivery_type: deliveryType.value,
      ...deliveryTime.value && { delivery_datetime: deliveryDateTime.value },
    };

    store.dispatch(
      'products/getSingleRestaurantCategories',
      payload,
    );
  }
  if (isAuthenticated.value) { store.dispatch('order/fetchOrderHistory', { limit: 3 }); }
  initObserver();
});

onUnmounted(() => {
  window.removeEventListener('scroll', onScroll);
});
</script>

<style src='./style.scss' lang="scss" scoped />

